import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { CTX } from 'Contexts/Global'

const RootStyles = ({ theme }) => {
  const $ = theme

  const Component = createGlobalStyle`
    body {
      ${$.colorizeText($.phosphorus)}
      opacity: 1;
      max-width: 100vw;
      font: normal 14px/20px monospace;
      font-family: "mplus-1m", Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
      letter-spacing: 0.05em;
      background: #0a0a0a;
      margin: 0;
      padding: 2ch;
    }

    a {
      ${$.colorizeText('#fff')}
      text-decoration: none;

      &:hover { text-decoration: underline; }
    }

    form {
      opacity: 0;
      position: absolute;
    }

    em {
      font-style: normal;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      // Prevent blank lines from collapsing
      li:after {
        content: '*';
        visibility: hidden;
      }
    }
  `

  return <Component />
}

export default () => <CTX component={RootStyles} />
